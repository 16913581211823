import './GameModeSelect.css';

const GameModeSelect = () => {
    return (
      <div className="select">
        <a href='narrative'>Narrative</a>
        <a href='battle'>Battle</a>
      </div>
    );
  }
  
  export default GameModeSelect;